<template>
  <div class="x" data-test="x" :dir="documentDirection">
    <SnippetConfigExtraParams />
    <SnippetCallbacks />
    <Tagging />
    <UrlHandler />
    <transition name="none">
      <RouterView />
    </transition>
  </div>
</template>

<script lang="ts">
  import { SnippetCallbacks, SnippetConfig, XProvide } from '@empathyco/x-components';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { Component, Inject, Provide, Vue, Watch } from 'vue-property-decorator';
  import { QueryPreviewInfo } from '@empathyco/x-components/queries-preview';
  import { useDevice } from './composables/use-device.composable';
  import currencies from './i18n/currencies';
  import './tailwind/index.css';

  @Component({
    components: {
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      UrlHandler
    }
  })
  export default class App extends Vue {
    @Inject('snippetConfig')
    protected snippetConfig!: SnippetConfig;
    protected device = useDevice();

    protected get documentDirection(): string {
      return (
        document.documentElement.dir ||
        document.body.dir ||
        (this.snippetConfig.documentDirection ?? 'ltr')
      );
    }

    @Provide('currencyFormat')
    public get currencyFormat(): string {
      return currencies[this.snippetConfig.currency!];
    }

    @XProvide('queriesPreviewInfo')
    public get queriesPreviewInfo(): QueryPreviewInfo[] | undefined {
      return this.snippetConfig.queriesPreview;
    }

    @Watch('snippetConfig.uiLang')
    syncLang(uiLang: string): void {
      this.$setLocale(uiLang);
    }

    @Watch('device.deviceName')
    syncDevice(deviceName: string): void {
      this.$setLocaleDevice(deviceName);
    }

    beforeMount(): void {
      const params = new URLSearchParams(location.search);
      let areaParam = params.get('area');
      let area;
      if (this.$router.currentRoute.path === '/amagijon' || location.pathname === '/amagijon') {
        areaParam = 'Asturias';
        this.$x.emit('UserChangedExtraParams', { area: areaParam });
      }
      const queryParam = params.get('query');
      const storeParam = params.get('store');

      if (areaParam === 'Comunidad de Madrid' || areaParam === 'Asturias') {
        area = areaParam;
      } else if (queryParam) {
        // when a query exist and no city es selected, we undertand its Madrid
        area = 'Comunidad de Madrid';
      }
      this.$x.emit('UserPickedParams', {
        area: area ?? undefined,
        name: storeParam ?? undefined
      });
      if (storeParam) {
        this.$x.emit('GetStorePreview', { store: storeParam });
      }
    }
  }
</script>

<style scoped>
  .x-modal::v-deep .x-modal__content {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
  }
</style>
<style lang="scss">
  *:not(.x-keyboard-navigation *) {
    outline: none;
  }

  .x-banner,
  .x-promoted {
    &__title {
      display: none;
    }
  }

  .none-enter-active,
  .none-leave-active {
    transition: none !important;
  }
  .none-enter, .none-leave-to /* .none-leave-active in <2.1.8 */ {
    opacity: 1 !important;
  }
</style>
