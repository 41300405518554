












































































































  import { defineComponent } from 'vue';
  import { useDevice } from '../composables/use-device.composable';

  import Logo from '../components/icons/logo.vue';
  import CookieBanner from '../components/cookie-banner.vue';
  import Footer from '../components/footer.vue';

  export default defineComponent({
    components: {
      Footer,
      CookieBanner,
      Logo
    },
    setup() {
      const { isDesktopOrGreater } = useDevice();

      return { isDesktopOrGreater };
    }
  });
