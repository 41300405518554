import { CustomSearchXStoreModule } from '../types';
import { adapter } from '../../../adapter/adapter';

export interface StoreParams {
  area: string | undefined;
  name: string | undefined;
}
/**
 * Default implementation for the {@link fetchStoreInfoResponse}.
 *
 * @param _context
 * @param params
 * @returns A Promise of search response that resolves when it fetches search response.
 *
 * @public
 */
// eslint-disable-next-line max-len
export const fetchStoreInfoResponse: CustomSearchXStoreModule['actions']['fetchStoreInfoResponse'] =
  (_context, params?: StoreParams) => {
    if (!params?.name) {
      return params?.area ? adapter.storeInfo({ area: params.area }) : adapter.storeInfo({});
    } else {
      return adapter.storeInfo({ name: params.name });
    }
  };
