
































































































































































































































































































































  import { defineComponent } from 'vue';
  import Logo from '../components/icons/logo.vue';
  import Footer from '../components/footer.vue';
  import CookieBanner from '../components/cookie-banner.vue';

  export default defineComponent({
    components: { CookieBanner, Footer, Logo },
    setup() {
      return {};
    }
  });
