<template>
  <div
    class="x-flex x-w-full x-flex-col x-justify-center x-gap-20 x-px-16 x-pb-128 x-text-center desktop:x-gap-40"
  >
    <div class="x-flex x-h-[100px] x-justify-center x-px-48 x-pb-40 desktop:x-h-[80px]">
      <div @click="$router.push('/')" @keydown.enter="$router.push('/')" class="x-cursor-pointer">
        <Logo class="x-w-[250px]" />
      </div>
    </div>
    <h1 class="x-font-main x-text-3xl x-font-bold">Condiciones Generales de Uso Motivemarket</h1>
    <div class="x-flex x-flex-col x-gap-20 x-text-left desktop:x-px-[15vw]">
      <h2 class="x-font-main x-text-[21px] x-font-[600]">1. Introducción</h2>
      <div>
        <span class="x-font-main x-text-md x-font-[300]">
          El objeto de las presentes Condiciones Generales de Uso (en adelante,
          <span class="x-font-bold">"Condiciones"</span>
          ) es regular el acceso y el uso del sitio web
          <a class="x-font-bold x-text-auxiliary-50 x-underline" href="https://motivemarket.com/">
            https://motivemarket.com/
          </a>
          (en adelante, el
          <span class="x-font-bold">“Sitio”</span>
          o
          <span class="x-font-bold">"Motivemarket"</span>
          ), propiedad de
          <a class="x-font-bold x-text-auxiliary-50 x-underline" href="https://motive.co/es">
            Motive Commerce Search Corp S.L.
          </a>
          , con domicilio social en calle Jimena Fernández de la Vega, 101, Parque tecnológico de
          Gijón, 33394, Asturias y NIF B09764838 (en adelante,
          <span class="x-font-bold">“Motive”</span>
          ) , así como los servicios ofrecidos a través del mismo que se describirán a continuación.
          <br />
          <br />
          Al acceder o utilizar el Sitio, usted (en adelante, el
          <span class="x-font-bold">“Usuario"</span>
          ) acepta cumplir con estas Condiciones.
        </span>
      </div>
      <h2 class="x-font-main x-text-[21px] x-font-[600]">
        2. Ámbito de aplicación y descripción del Servicio
      </h2>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>2.1</span>
        <span>
          Motive a través del Sitio proporciona un servicio gratuito de búsqueda de productos y
          comparación de precios para ayudar al Usuario a tomar decisiones de compra. Los productos
          mostrados en Motivemarket son ofrecidos por terceros minoristas en línea y otros
          proveedores de productos en Internet que participan en Motivemarket (en lo sucesivo,
          <span class="x-font-bold">"Prestadores”</span>
          ).
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>2.2</span>
        <span>
          Motivemarket actúa como mero comparador sin intervenir en el proceso de compra.
          Motivemarket actúa como intermediario que facilita la visibilidad de los productos y la
          conexión entre Prestadores y Usuarios, pero no interviene en la transacción final ni en la
          gestión del pago.
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>2.3</span>
        <span>
          La información sobre productos y precios se obtiene de los propios Prestadores que
          participan en Motivemarket. Si el Usuario deseara adquirir alguno de los productos
          ofrecidos deberá completar el proceso de compra directamente en la tienda en línea del
          Prestador, siguiendo los pasos y políticas específicas del mismo.
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>2.4</span>
        <span>
          Motive no garantiza la exactitud, veracidad, integridad o falta de actualización de dicha
          información y recomendamos al Usuario verificarla directamente con cada proveedor antes de
          realizar una compra.
        </span>
      </div>
      <h2 class="x-font-main x-text-[21px] x-font-[600]">3. Obligaciones del Usuario</h2>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>3.1</span>
        <div class="x-flex x-flex-col x-gap-20">
          <span>El Usuario se compromete a:</span>
          <ul class="x-list-disc x-pl-40">
            <li>
              Hacer un uso diligente, correcto y lícito del Sitio, respetando la legislación
              vigente, la moral y las buenas costumbres, así como el orden público.
            </li>
            <li>
              Revisar periódicamente las presentes Condiciones, o cualesquiera otras condiciones
              aplicables, comprobando los cambios que, en su caso, pudieran haber sufrido.
            </li>
            <li>
              Controlar las notificaciones que, en su caso, le sean remitidas por Motive, puesto que
              pueden incluir información importante.
            </li>
            <li>
              No recabar información o contenidos para prestar otros servicios que puedan suponer
              una clara competencia para Motive.
            </li>
            <li>
              No modificar o tratar de modificar el Sitio en modo alguno ni realizar acciones o
              utilizar medios orientados a simular la apariencia o las funciones de la misma.
            </li>
            <li>
              Abstenerse de llevar a cabo acción alguna que conlleve la introducción de virus
              informáticos, gusanos, troyanos o cualquier otra clase de código malicioso destinado a
              interrumpir, destruir o limitar las funcionalidades del Sitio.
            </li>
            <li>
              No emplear técnicas de ingeniería inversa y/o descifrar, descompilar o utilizar
              cualquier otro sistema destinado a conocer el código fuente del Sitio o de cualquier
              elemento sujeto a copyright o propiedad intelectual subyacente.
            </li>
            <li>
              No dañar, deshabilitar, sobrecargar o dificultar el servicio (o la red o redes
              conectadas al servicio), o interferir en el uso y disfrute del mismo.
            </li>
            <li>
              En cualquier caso, no realizar ningún tipo de actos que puedan infringir derechos o
              intereses de Motive o de terceros como puedan ser, a modo de ejemplo, derechos de
              propiedad intelectual o industrial (patentes, marcas, derechos de copyright, secretos
              comerciales...).
            </li>
          </ul>
        </div>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>3.2</span>
        <span>
          El uso del Sitio está permitido únicamente a personas mayores de 18 años. Al acceder o
          utilizar el Servicio de Motivemarket, el Usuario declara y garantiza que tiene al menos 18
          años.
        </span>
      </div>
      <h2 class="x-font-main x-text-[21px] x-font-[600]">4. Exención de Responsabilidad</h2>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>4.1</span>
        <span>
          Motive trabaja para que los servicios y funcionalidades del Sitio se encuentren siempre
          disponibles. No obstante, el Sitio será mostrado “tal cual”, según la disponibilidad y las
          limitaciones que concurran en cada momento.
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>4.2</span>
        <div class="x-flex x-flex-col x-gap-20">
          <span>
            A pesar del continuo esfuerzo de Motive por proteger los sistemas y los contenidos
            incluidos en el Sitio, empleando para ello los estándares de seguridad habituales en
            Internet, no es posible ofrecer plenas garantías en relación con las intrusiones o
            pérdidas de información que puedan producirse.
          </span>
          <span>
            Del mismo modo que no puede garantizarse la ausencia de virus o de otros elementos
            dañinos en el Sitio o en sitios de terceros que puedan producir alteraciones en el
            sistema informático, tanto software como hardware, del usuario. Por esta razón, el
            Usuario asume y comprende que existan situaciones que puedan escapar al control de
            Motive.
          </span>
        </div>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>4.3</span>
        <span>
          La información relativa a los productos y precios ofrecidos en el Sitio es proporcionada
          por sus respectivos Prestadores. Aunque Motive insta a éstos a comprobarla y revisarla, de
          conformidad con el artículo 16 de la Ley 34/2002, de 11 de julio, de Servicios de la
          Sociedad de la Información y de Comercio Electrónico no asume ninguna responsabilidad al
          respecto, por cuanto la misma es publicada de manera automática en el Sitio y no se
          encuentra sometida a revisión por parte de Motive, por lo que no puede controlarla y
          verificarla ni ofrecer garantías frente a posibles inexactitudes en dichas descripciones,
          precios, fotografías, etc.
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>4.4</span>
        <div class="x-flex x-flex-col x-gap-20">
          <span>
            Motive queda eximido toda responsabilidad que se derive de un mal uso del Sitio por el
            Usuario, así como por el incumplimiento de las obligaciones o compromisos asumidos en
            virtud de las presentes Condiciones.
          </span>
          <span>
            Motive no será responsable en caso de lucro cesante o daño emergente por cualquier
            cuestión.
          </span>
        </div>
      </div>
      <h2 class="x-font-main x-text-[21px] x-font-[600]">5. Relaciones contractuales</h2>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>5.1</span>
        <div class="x-flex x-flex-col x-gap-20">
          <span>
            Motivemarket y los Prestadores operan sus respectivos servicios de forma independiente.
            Los Usuarios interesados en adquirir un producto serán dirigidos al portal del Prestador
            del que procede la oferta en cuestión. Los Usuarios son responsables de comprobar toda
            la información relevante antes de comprar un producto.
          </span>
          <span>
            Cualquier transacción o relación contractual que surja del uso de nuestro servicio será
            entre el Usuario y el Prestador del producto o servicio correspondiente. Todas las
            obligaciones contractuales derivadas y relacionadas con estos contratos surgen
            exclusivamente entre los usuarios y los prestadores.
          </span>
        </div>
      </div>
      <h2 class="x-font-main x-text-[21px] x-font-[600]">6. Contenidos y enlaces de terceros</h2>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>6.1</span>
        <span>
          Motivemarket no tiene la obligación general de monitorizar los contenidos transmitidos por
          los Prestadores ni de buscar activamente hechos o circunstancias que indiquen la
          existencia de actividades ilícitas o la incompatibilidad de estos contenidos con la
          normativa legal, los derechos de terceros o las condiciones contractuales aplicables.
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>6.2</span>
        <span>
          No obstante, Motivemarket se reserva el derecho a revisar voluntariamente los contenidos
          por propia iniciativa y a su entera discreción, con el fin de reconocer y determinar
          contenidos ilícitos o que infrinjan las condiciones contractuales aplicables y eliminar o
          bloquear el acceso a los mismos, así como a adoptar todas las medidas necesarias de
          conformidad con las condiciones contractuales aplicables.
        </span>
      </div>
      <h2 class="x-font-main x-text-[21px] x-font-[600]">7. Propiedad intelectual e industrial</h2>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>7.1</span>
        <span>
          Todo el contenido del Sitio, incluyendo textos, gráficos, logotipos, iconos, imágenes,
          descargas digitales y bases de datos, es propiedad de Motivemarket y está protegido por
          las leyes de propiedad intelectual. El uso no autorizado de cualquier material contenido
          en el Sitio puede violar leyes de copyright, marcas registradas y otras leyes aplicables.
          El Usuario se compromete a respetar todos los derechos de propiedad intelectual y a no
          utilizar el contenido del Sitio para fines comerciales sin obtener el previo
          consentimiento por escrito de Motivemarket.
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>7.2</span>
        <span>
          Lo anterior no incluirá los datos y cualesquiera contenidos introducidos o facilitados por
          los Prestadores, cuya responsabilidad y derechos corresponderá exclusivamente a éstos.
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>7.3</span>
        <span>
          Queda prohibido cualquier uso o explotación de los elementos indicados en el apartado 7.1
          de forma contraria a la ley, incluyendo la reproducción, transformación, distribución o
          comunicación de pública, sin autorización expresa y por escrito de sus respectivos
          titulares, a excepción de aquellos usos que se deriven o sean necesarios para hacer uso de
          Servicios con arreglo a estas Condiciones.
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>7.4</span>
        <span>
          Cualquier infracción de lo anterior dará lugar al ejercicio de las acciones civiles o
          penales que correspondan, y a la reclamación por los daños y perjuicios causados.
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>7.5</span>
        <span>
          No está permitida la extracción sistemática ni la reutilización de parte alguna del
          contenido de ninguno de los Servicios sin nuestro expreso consentimiento por escrito. En
          particular, no se permite el uso de herramientas o robots de búsqueda y extracción de
          datos para la extracción (ya sea en una o varias ocasiones) de partes sustanciales de los
          Servicios para su reutilización sin el expreso consentimiento por escrito de Motive.
          Tampoco le está permitido al Usuario crear ni publicar sus propias bases de datos cuando
          éstas contengan partes sustanciales de cualquiera de los Servicios (por ejemplo, nuestras
          listas de productos y listas de precios) sin el expreso consentimiento por escrito por
          parte de Motive
        </span>
      </div>
      <h2 class="x-font-main x-text-[21px] x-font-[600]">8. Comunicaciones</h2>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>8.1</span>
        <span>
          El Usuario puede ponerse en contacto con Motive para comunicar cualquier incidencia en:
          <a href="mailto:info@motive.co" class="x-font-bold x-text-auxiliary-50 x-underline">
            info@motive.co
          </a>
        </span>
      </div>
      <h2 class="x-font-main x-text-[21px] x-font-[600]">9. Modificación</h2>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>9.1</span>
        <span>
          Motivemarket se reserva en todo momento el derecho de modificar las Condiciones. El uso
          continuado del Sitio implica la aceptación de las nuevas Condiciones.
        </span>
      </div>
      <span class="x-font-main x-text-[21px] x-font-[600]">
        10. Legislación aplicable y jurisdicción
      </span>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>10.1</span>
        <span>
          Estas Condiciones se regirán e interpretarán de acuerdo con las leyes de España.
        </span>
      </div>
      <div class="x-flex x-gap-12 x-font-main x-text-md x-font-[300] desktop:x-pl-20">
        <span>10.2</span>
        <span>
          Cualquier controversia o reclamación derivada de, o relacionada con, el uso del Sitio o
          con estas Condiciones, que no pueda resolverse de manera amistosa, se someterá a la
          jurisdicción exclusiva de los juzgados y tribunales de la ciudad de Gijón.
        </span>
      </div>
    </div>
    <CookieBanner />
    <Footer class="x-left-0" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import Logo from '../components/icons/logo.vue';
  import Footer from '../components/footer.vue';
  import CookieBanner from '../components/cookie-banner.vue';

  export default defineComponent({
    components: { CookieBanner, Footer, Logo },
    setup() {
      return {};
    }
  });
</script>

<style lang="scss"></style>
