<template>
  <Transition name="cookie-banner--animated">
    <div
      v-if="visible"
      class="x-fixed x-bottom-[38px] x-left-[50%] x-right-[50%] x-z-[99999999] x-flex x-w-[100%] x-max-w-[max-content] x-translate-x-[-50%] x-flex-col x-gap-[22px] x-rounded-lg x-bg-neutral-0 x-p-24 x-pb-12 x-text-center x-shadow-lg tablet:x-gap-32"
    >
      <div class="x-flex x-justify-around">
        <p
          class="x-max-w-[320px] x-font-million x-text-[24px] x-leading-[28px] tablet:x-max-w-[503px] tablet:x-text-[36px] tablet:x-leading-[36px]"
        >
          {{ $t('cookieBanner.title') }}
        </p>
        <button
          @click="hideBanner"
          class="x-m-[4px] x-ml-[8px] x-h-max x-appearance-none tablet:x-m-[8px]"
        >
          <CrossIcon />
        </button>
      </div>
      <div class="x-flex x-items-center x-justify-center x-gap-4">
        <img class="x-absolute x-bottom-0 -x-z-[1]" src="shadow.png" alt="Dot" />
        <p class="x-text-[8px]">{{ $t('cookieBanner.poweredBy') }}</p>
        <a href="https://www.visibleprivacy.com/" target="_blank">
          <img
            class="x-h-[14px] tablet:x-h-[16px]"
            src="visible-privacy.svg"
            alt="Visible Privacy"
          />
        </a>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import { onBeforeRouteLeave } from 'vue-router/composables';
  import { CrossIcon } from '@empathyco/x-components';

  export default defineComponent({
    name: 'CookieBanner',
    components: { CrossIcon },
    setup() {
      const visible = ref(false);

      const getCookieValue = (): string | null => {
        return localStorage.getItem('x-my-motive-show-cookie-banner');
      };

      const setCookieValue = (value: string): void => {
        localStorage.setItem('x-my-motive-show-cookie-banner', value);
      };

      const hideBanner = (): void => {
        setCookieValue('false');
        visible.value = false;
      };

      onMounted(() => {
        const isBannerVisible = getCookieValue();
        if (!isBannerVisible) {
          setCookieValue('true');
        } else if (isBannerVisible === 'false') {
          return;
        }
        visible.value = true;
      });

      onBeforeRouteLeave((to, from, next) => {
        const bannerStatus = getCookieValue();
        if (bannerStatus === 'true') {
          hideBanner();
        }
        next();
      });

      return {
        visible,
        hideBanner
      };
    }
  });
</script>

<style lang="scss" scoped>
  .cookie-banner {
    &--animated {
      &-enter-active,
      &-leave-active {
        transition: opacity 0.6s ease, transform 1.2s ease;
      }

      &-enter-from,
      &-leave-to {
        opacity: 0;
        transform: translateY(100%);
      }
    }
  }
</style>
