import { RouteConfig } from 'vue-router';
import AboutUs from '../pages/abous-us.vue';
import LegalTerms from '../pages/legal-terms.vue';

export default <RouteConfig[]>[
  {
    path: '/',
    component: () => import('../components/custom-main-modal.vue').then(d => d.default)
  },
  {
    path: '/about-us',
    component: AboutUs
  },
  {
    path: '/legal',
    component: LegalTerms
  },
  {
    path: '/amagijon',
    component: () => import('../components/custom-main-modal.vue').then(d => d.default)
  },
  {
    path: '/contact',
    component: () => import('../pages/contact.vue')
  },
  {
    path: '/privacy-policy',
    component: () => import('../pages/privacy-policy.vue')
  },
  {
    path: '/sitemap',
    component: () => import('../pages/sitemap.vue')
  },
  {
    path: '*',
    component: () => import('../components/custom-main-modal.vue').then(d => d.default)
  }
];
