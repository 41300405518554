import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';
import { Dictionary } from '@empathyco/x-utils';
import { getEnvironment } from '../../utils/environment';

/**
 * Returns the store counter endpoint URL based on the current environment.
 *
 * Depending on the host of the current document location, it will concatenate
 * the appropriate environment variable with the store counter path.
 *
 * @returns The complete store counter endpoint URL.
 */
function getStoreCounterEndpoint(): string {
  const storeCounterPath = 'query/mymotivemarketplace/store-counter?lang=es';

  const endpoint: Dictionary<string> = {
    live: process.env.VUE_APP_SEARCH_ENDPOINT_PROD,
    staging: process.env.VUE_APP_SEARCH_ENDPOINT_STAGING
  };

  const env = getEnvironment();

  return `${endpoint[env]}${storeCounterPath}`;
}

export const storeCounter = endpointAdapterFactory<any, any>({
  endpoint: getStoreCounterEndpoint(),
  responseMapper: schemaMapperFactory<any, any>({
    totalProducts: response => response.catalog.numFound ?? 300000,
    totalShops: response => response.catalog.counts.total_stores ?? 127
  })
});
