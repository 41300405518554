













































































  import { defineComponent, ref } from 'vue';
  import { ChevronUpIcon } from '@empathyco/x-components';
  import { useDevice } from '../composables/use-device.composable';
  import { useUrlParameterComposable } from '../composables/use-url-parameter.composable';
  import InstagramIcon from './icons/instagram-icon.vue';
  import Logo from './icons/logo.vue';

  export default defineComponent({
    name: 'Footer',
    components: { InstagramIcon, Logo, ChevronUpIcon },
    setup() {
      const { isDesktop } = useDevice();
      const isFooterOpen = ref(false);
      const { areaParameter } = useUrlParameterComposable();

      const params = new URLSearchParams(location.search);
      const envParam = params.get('env');
      const displayEnv = envParam ? '?env=' + envParam : '';

      return {
        isDesktop,
        isFooterOpen,
        areaParameter,
        displayEnv
      };
    }
  });
