/**
 * Auxiliar function to get the environment.
 *
 * @returns The actual environment.
 */
export function getEnvironment(): string {
  const { hostname, search } = window.location;
  const urlParams = new URLSearchParams(search);
  const env = urlParams.get('env');

  if (env && ['live', 'staging'].includes(env)) {
    return env;
  }

  if (hostname === 'motivemarket.com') {
    return 'live';
  }

  return 'staging';
}
