import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';
import { Dictionary } from '@empathyco/x-utils';
import { getProxyServiceURL } from '../mappers/schemas/results';
import { getEncodedSignature } from '../../utils/encryption';
import { getEnvironment } from '../../utils/environment';
import { MyMotiveStore } from '../models/entitites/result';

/**
 * Returns the store info endpoint URL based on the current environment.
 *
 * Depending on the host of the current document location, it will concatenate
 * the appropriate environment variable with the store counter path.
 *
 * @returns The complete store counter endpoint URL.
 */
function getStoreInfoEndpoint(): string {
  const storeInfoPath = 'query/mymotivemarketplace/stores';

  const endpoint: Dictionary<string> = {
    live: process.env.VUE_APP_SEARCH_ENDPOINT_PROD,
    staging: process.env.VUE_APP_SEARCH_ENDPOINT_STAGING
  };

  const env = getEnvironment();

  return `${endpoint[env]}${storeInfoPath}`;
}

export const storeInfo = endpointAdapterFactory<any, any>({
  endpoint: getStoreInfoEndpoint,
  responseMapper: schemaMapperFactory<any, any>({
    storeInfo: response => storeInfoMapper(response.stores.content as MyMotiveStore[])
  }),
  defaultRequestOptions: {
    parameters: {
      start: '0',
      rows: '300'
    }
  }
});

/**
 * Maps result images by prepending the proxy service URL to each one.
 *
 * @param image - The images to map.
 * @returns A mapped images.
 */
function mapImage(image: string): string {
  const proxyURL = getProxyServiceURL();
  return proxyURL + getEncodedSignature(image);
}

interface StaticImages {
  [key: string]: string;
}

export const staticLogos: StaticImages = {
  '250 Gramos de Queso': '250_gramos_de_queso.png',
  'Agroalimentaria del Oriente SL': 'agroalimentaria_del_oriente_sl.png',
  'Alskin Cosmetics': 'alskin_cosmetics.png',
  aresso: 'aresso.png',
  arteago: 'arteago.png',
  Asvarta: 'asvarta.png',
  Bevilud: 'bevilud.png',
  Biciclasica: 'biciclasica.png',
  Bigoutlet: 'bigoutlet.png',
  Biodieta: 'biodieta.png',
  Bruyere: 'bruyere.png',
  Cafento: 'cafento.png',
  'Carlitos Baby': 'carlitos_baby.png',
  'Chupatintas S.L.': 'chupatintas_sl.png',
  'Coalla Gourmet': 'coalla_gourmet.png',
  'Coral Golf': 'coral_golf.png',
  coseno: 'coseno.png',
  'Covadonga Sports': 'covadonga_sports.png',
  'Culture Bike': 'culture_bike.png',
  'EMBUTIDOS LA UNION, S.L.': 'emubtidos_la_union_sl.png',
  Endorshop: 'endorshop.png',
  'Find Your Everest': 'find_your_everest.png',
  gam: 'gam.png',
  'Hoopoe Running': 'hoopoe_running.png',
  'IPG Dental': 'ipg_dental.png',
  'Joyeria Roibás': 'joyeria_roibas.png',
  'CAPUA HOBBY’S': 'capua_hobbys.png',
  'Letty B.M.': 'letty_bm.png',
  Parafernalia: 'parafernalia.png',
  Reveymo: 'reveymo.png',
  'Sherman Survival': 'sherman_survival.png',
  siroko: 'siroko.png',
  sporting: 'sporting.png',
  'Tablas Surf Shop': 'tablas_surf_shop.png',
  'Tejidos El Mundo': 'tejidos_el_mundo.png',
  'Tiendas Marvelous': 'tiendas_marvelous.png',
  'Universo Balonmano': 'universo_balonmano.png',
  ZonaIndoor: 'zonaindoor.png',
  Redink: 'redink.png',
  Kbike: 'kbike.png',
  Regalos007: 'regalos007.png',
  'Union Ferretera': 'unionferretera.png'
};

export const staticBackground: StaticImages = {
  'CAPUA HOBBY’S': 'capua_hobbys.jpg',
  'Chupatintas S.L.': 'chupatintas.jpg',
  Bigoutlet: 'bigoutlet.jpg',
  'Union Ferretera': 'unionferretera.jpg'
};

/**
 * Maps the store info from the response.
 *
 * @param infoArray - The info array.
 * @returns The mapped store info.
 */
function storeInfoMapper(infoArray: MyMotiveStore[]): MyMotiveStore[] {
  infoArray.forEach((store: MyMotiveStore) => {
    if (store.logoUrl || staticLogos[store.name.toString()]) {
      if (staticLogos[store.name.toString()]) {
        store.logoUrl = `logos/${staticLogos[store.name.toString()]}`;
      } else {
        store.logoUrl = mapImage(store.logoUrl);
      }
    }
    if (store.photoUrl || staticBackground[store.name.toString()]) {
      if (staticBackground[store.name.toString()]) {
        store.photoUrl = `background/${staticBackground[store.name.toString()]}`;
      } else {
        store.photoUrl = mapImage(store.photoUrl);
      }
    }
  });
  return infoArray;
}
