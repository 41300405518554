<template>
  <div class="x-z-[99999]">
    <div
      v-if="!isDesktop && isFooterOpen"
      @click="isFooterOpen = false"
      @keydown.enter="isFooterOpen = false"
      class="x-absolute x-bottom-0 x-h-[100dvh] x-w-full x-bg-neutral-50/[0.1]"
      data-test="footer-mobile-background"
    ></div>

    <div
      class="x-transition-all x-fixed x-flex x-w-full x-flex-col x-items-center x-justify-between x-gap-[24px] x-bg-neutral-0 x-py-[10px] x-font-main x-text-md x-text-neutral-90 x-duration-500 desktop:x-h-[36px] desktop:x-flex-row desktop:x-px-[50px]"
      :class="isDesktop || isFooterOpen ? 'x-bottom-0' : 'x-bottom-[-250px]'"
      data-test="footer-container"
    >
      <component
        :is="isDesktop ? 'div' : 'button'"
        @click="!isDesktop && (isFooterOpen = !isFooterOpen)"
        class="x-flex x-h-[36px] x-flex-row x-items-center x-gap-4"
        data-test="footer-logo-container"
      >
        <span
          v-if="areaParameter === 'Asturias'"
          class="x-flex x-flex-row x-items-center x-gap-4"
          data-test="footer-amagijon-label"
        >
          <span class="x-text-[14px] x-font-[800]">amagijon</span>
          <span class="x-text-[8px] x-font-[275]">x</span>
        </span>
        <Logo class="x-h-[20px] x-w-[146px]" />
        <ChevronUpIcon
          v-if="!isDesktop"
          class="x-transition-all x-duration-500"
          :class="isFooterOpen ? 'x-rotate-0' : '-x-rotate-180'"
        />
      </component>

      <div
        class="x-flex x-flex-col x-items-center x-gap-[16px] x-pb-[10px] x-text-xs x-font-[300] desktop:x-flex-row desktop:x-gap-[24px] desktop:x-pb-0"
      >
        <router-link :to="`/about-us${displayEnv}`" data-test="footer-about-us-link">
          {{ $t('onboarding.aboutUs') }}
        </router-link>
        <router-link :to="`/contact${displayEnv}`" data-test="footer-contact-link">
          {{ $t('onboarding.contact') }}
        </router-link>
        <router-link :to="`/privacy-policy${displayEnv}`" data-test="footer-privacy-policy-link">
          {{ $t('onboarding.privacyPolicy') }}
        </router-link>
        <router-link :to="`/legal${displayEnv}`" data-test="footer-use-conditions-link">
          {{ $t('onboarding.legal') }}
        </router-link>
        <a
          href="https://www.motive.co/es/terms-and-conditions"
          target="_blank"
          data-test="footer-terms-and-conditions-link"
        >
          {{ $t('onboarding.termsAndConditions') }}
        </a>
        <router-link :to="`/sitemap${displayEnv}`" data-test="footer-sitemap-link">
          Sitemap
        </router-link>
        <span v-if="isDesktop" class="x-text-[16px] x-font-[300]" data-test="footer-separator">
          |
        </span>
        <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
        <a href="https://www.instagram.com/motivemarket.com_/" target="_blank">
          <InstagramIcon
            class="x-bg-neutral-0 x-stroke-neutral-50"
            aria-label="Visit our Instagram page"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { ChevronUpIcon } from '@empathyco/x-components';
  import { useDevice } from '../composables/use-device.composable';
  import { useUrlParameterComposable } from '../composables/use-url-parameter.composable';
  import InstagramIcon from './icons/instagram-icon.vue';
  import Logo from './icons/logo.vue';

  export default defineComponent({
    name: 'Footer',
    components: { InstagramIcon, Logo, ChevronUpIcon },
    setup() {
      const { isDesktop } = useDevice();
      const isFooterOpen = ref(false);
      const { areaParameter } = useUrlParameterComposable();

      const params = new URLSearchParams(location.search);
      const envParam = params.get('env');
      const displayEnv = envParam ? '?env=' + envParam : '';

      return {
        isDesktop,
        isFooterOpen,
        areaParameter,
        displayEnv
      };
    }
  });
</script>
