<template>
  <div class="x-max-w-[100vw] x-overflow-hidden x-whitespace-pre-line">
    <div class="x-flex x-h-[100px] x-justify-center x-px-48 x-pb-40 desktop:x-h-[80px]">
      <div @click="$router.push('/')" @keydown.enter="$router.push('/')" class="x-cursor-pointer">
        <Logo class="x-w-[250px]" />
      </div>
    </div>
    <div class="x-flex x-h-full x-w-screen x-flex-col">
      <div
        class="x-mb-28 x-flex x-flex-col x-gap-40 x-px-16 x-pb-80 x-pt-16 x-font-main desktop:x-mb-[70px] desktop:x-px-[16vw]"
      >
        <h1 class="x-text-center x-text-3xl x-font-[800] desktop:x-text-6xl">
          {{ $t('aboutUs.title1') }}
        </h1>
        <img
          class="x-h-[400px] x-rounded-lg x-object-cover desktop:x-h-auto"
          alt="Women shopping"
          src="about-us-1.png"
        />
        <span class="x-text-md x-font-[300]">{{ $t('aboutUs.body1') }}</span>
      </div>
      <div class="x-h-fit x-w-full x-bg-[#F5F5F5] desktop:x-h-screen desktop:x-max-h-[730px]">
        <div
          class="x-mb-28 x-flex x-flex-col x-items-center x-gap-40 x-px-[16px] x-pb-32 x-pt-64 x-font-main desktop:x-mt-[-70px] desktop:x-flex-row desktop:x-px-[16vw] desktop:x-pb-160 desktop:x-pt-0"
        >
          <img alt="Local shopping" src="about-us-2.png" />
          <dir class="x-mt-0 x-flex x-flex-col x-gap-40 x-pl-0">
            <span
              class="x-text-[28px] x-font-[800] desktop:x-col-start-2 desktop:x-row-start-1 desktop:x-text-4xl"
            >
              {{ $t('aboutUs.title2') }}
            </span>
            <span class="x-text-md x-font-[300] desktop:x-col-start-2 desktop:x-row-start-1">
              {{ $t('aboutUs.body2') }}
            </span>
          </dir>
        </div>
      </div>
      <div class="x-w-full">
        <div
          class="x-mb-28 x-flex x-flex-col x-gap-40 x-px-[16px] x-pt-64 x-font-main desktop:x-grid desktop:x-grid-cols-7 desktop:x-px-[16vw] desktop:x-pt-[100px]"
        >
          <h2
            class="x-order-2 x-col-span-4 x-text-[28px] x-font-[800] desktop:x-col-start-1 desktop:x-row-start-1 desktop:x-mt-32 desktop:x-w-[85%] desktop:x-text-4xl"
          >
            {{ $t('aboutUs.title3') }}
          </h2>
          <img
            alt="Empowering women"
            class="x-order-1 desktop:x-col-span-3 desktop:x-col-start-5 desktop:x-row-start-1 desktop:x-w-[30vw]"
            src="about-us-3.png"
          />
          <span
            class="x-order-3 x-col-span-4 x-text-md x-font-[300] desktop:x-col-start-1 desktop:x-row-start-1 desktop:x-mt-[334px]"
          >
            {{ $t('aboutUs.body3') }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="x-flex x-h-[300px] x-w-full x-flex-col x-items-center x-justify-center x-gap-48 x-bg-[#F5F5F5] x-text-center"
    >
      <span class="x-w-[500px] x-font-main x-text-[28px] x-font-[800] desktop:x-text-4xl">
        {{ $t('aboutUs.addYourShop') }}
      </span>
      <a
        class="x-button x-button-neutral x-w-[130px] x-rounded-full x-font-main x-text-md x-font-[700]"
        href="https://www.motive.co/"
        target="_blank"
      >
        {{ $t('aboutUs.moreInfo') }}
      </a>
    </div>
    <div
      class="x-flex x-flex-col x-items-center x-justify-center x-gap-40 x-px-12 x-py-80 desktop:x-gap-20 desktop:x-px-160"
    >
      <div class="x-mx-auto x-flex x-flex-col x-items-center desktop:x-flex-row">
        <a
          class="x-mt-8 x-text-center x-font-main x-text-[55px] x-font-[800]"
          href="https://motivemarket.com/amagijon"
        >
          amagijón
        </a>

        <span class="x-mx-8 x-text-center x-text-[24px]">X</span>
        <div
          @click="$router.push('/amagijon')"
          @keydown.enter="$router.push('/amagijon')"
          class="x-mt-[-12px] x-cursor-pointer desktop:x-mt-0"
        >
          <Logo class="x-h-[42px] x-w-[250px] desktop:x-h-[50px] desktop:x-w-[363px]" />
        </div>
      </div>
      <span class="x-font-main x-text-md x-font-[300] desktop:x-text-center">
        Al comprar, los clientes recurren a sitios como Amazon por falta de alternativas locales.
      </span>
      <span class="x-font-main x-text-md x-font-[300] desktop:x-text-center">
        MotiveMarket muestra a los compradores tanto los productos que buscan, como las tiendas y la
        ubicación en el mapa donde se encuentran, así los clientes de Gijón pueden decidir si
        pasarse a comprar por la tienda física o en la versión online.
      </span>
    </div>
    <CookieBanner />
    <Footer class="x-left-0" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useDevice } from '../composables/use-device.composable';

  import Logo from '../components/icons/logo.vue';
  import CookieBanner from '../components/cookie-banner.vue';
  import Footer from '../components/footer.vue';

  export default defineComponent({
    components: {
      Footer,
      CookieBanner,
      Logo
    },
    setup() {
      const { isDesktopOrGreater } = useDevice();

      return { isDesktopOrGreater };
    }
  });
</script>

<style lang="scss"></style>
