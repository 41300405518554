import { CustomSearchXStoreModule } from '../types';

// eslint-disable-next-line max-len
export const saveStoreResultsResponse: CustomSearchXStoreModule['actions']['saveStoreResultsResponse'] =
  ({ commit }, { storeResults, productsPerShop }) => {
    if (storeResults) {
      commit('setStoreResults', storeResults);
      commit('setProductsPerShop', productsPerShop);
    }
  };
