


































  import { defineComponent, onMounted, ref } from 'vue';
  import { onBeforeRouteLeave } from 'vue-router/composables';
  import { CrossIcon } from '@empathyco/x-components';

  export default defineComponent({
    name: 'CookieBanner',
    components: { CrossIcon },
    setup() {
      const visible = ref(false);

      const getCookieValue = (): string | null => {
        return localStorage.getItem('x-my-motive-show-cookie-banner');
      };

      const setCookieValue = (value: string): void => {
        localStorage.setItem('x-my-motive-show-cookie-banner', value);
      };

      const hideBanner = (): void => {
        setCookieValue('false');
        visible.value = false;
      };

      onMounted(() => {
        const isBannerVisible = getCookieValue();
        if (!isBannerVisible) {
          setCookieValue('true');
        } else if (isBannerVisible === 'false') {
          return;
        }
        visible.value = true;
      });

      onBeforeRouteLeave((to, from, next) => {
        const bannerStatus = getCookieValue();
        if (bannerStatus === 'true') {
          hideBanner();
        }
        next();
      });

      return {
        visible,
        hideBanner
      };
    }
  });
