import { use$x } from '@empathyco/x-components';

import { computed, ComputedRef, Ref, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useSeoComposable } from './use-seo.composable';

let isSetUseUrlParameterComposable = false;
export const useUrlParameterComposable = (): {
  areaParameter: Ref<string | null>;
  storeParameter: Ref<string | null>;
  areaString: ComputedRef<string>;
  goHome: () => void;
  setAreaURLParam: (area: string) => void;
  setStoreURLParam: (store: string) => void;
  removeStoreURLParam: () => void;
} => {
  const $x = use$x();
  const router = useRouter();
  const areaParameter = ref<string>('');
  const storeParameter = ref(new URLSearchParams(location.search).get('store'));
  const { setSeo } = useSeoComposable();

  const areaString = computed((): string => {
    return areaParameter.value === 'Asturias' ? 'Gijón' : 'Madrid';
  });

  const goHome = (): void => {
    const params = new URLSearchParams(location.search);
    const env = params.get('env');
    const query = params.get('query');

    const updatedQuery = env ? '?env=' + env : '';

    if (query) {
      window.location.href = `${location.pathname}${updatedQuery}`;
    } else {
      window.location.href = `${location.pathname.replace('/amagijon', '/')}${updatedQuery}`;
    }
  };

  const setAreaURLParam = (area: string): void => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query');
    const env = params.get('env');
    const queryParam = env ? { env: env } : {};

    router.push({ path: '/amagijon', query: queryParam });

    areaParameter.value = 'Asturias';

    $x.emit('UserChangedExtraParams', { area: area });
    $x.emit('UserPickedArea', area);
    $x.emit('UserPickedParams', { area: area, name: storeParameter.value ?? undefined });
    setSeo({
      path: router.currentRoute.path,
      store: storeParameter.value ?? '',
      query: query ?? ''
    });
  };

  const setStoreURLParam = (store: string): void => {
    const params = new URLSearchParams(location.search);

    params.set('store', store);
    const query = params.get('query');
    params.delete('scroll');
    if (query) {
      params.delete('query');
      params.append('query', query);
    }
    $x.emit('GetStorePreview', { store: store });

    const updatedQuery = '?' + params.toString();
    history.pushState({}, '', `${location.pathname}${updatedQuery}`);

    $x.emit('UserChangedExtraParams', { store });
    $x.emit('UserPickedParams', { area: areaParameter.value ?? undefined, name: store });
    $x.emit('UserClosedOnBoarding');
    setSeo({
      path: router.currentRoute.path,
      store: store,
      query: query ?? ''
    });
  };

  const removeStoreURLParam = (): void => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query');
    if (!query) {
      $x.emit('UserOpenedOnBoarding');
    }
    params.delete('store');
    params.delete('scroll');

    const updatedQuery = '?' + params.toString();
    history.pushState({}, '', `${location.pathname}${updatedQuery}`);
    $x.emit('UserChangedExtraParams', { store: null });
    $x.emit('UserPickedParams', { area: areaParameter.value ?? undefined, name: '' });

    setSeo({
      path: router.currentRoute.path,
      query: query ?? ''
    });
  };

  $x.on('UserPickedParams').subscribe(params => {
    const area = params.area;
    if (router.currentRoute.path === '/amagijon') {
      areaParameter.value = 'Asturias';
    }
    if (area === 'Comunidad de Madrid') {
      areaParameter.value = 'madrid';
    }

    storeParameter.value = new URLSearchParams(location.search).get('store');
  });

  $x.on('UserPickedArea').subscribe(area => {
    if (router.currentRoute.path === '/amagijon') {
      areaParameter.value = 'Asturias';
    }
    if (area === 'Comunidad de Madrid') {
      areaParameter.value = 'madrid';
    }
  });

  if (!isSetUseUrlParameterComposable) {
    isSetUseUrlParameterComposable = true;
    $x.on('UserAcceptedAQuery').subscribe(query => {
      setSeo({
        path: router.currentRoute.path,
        store: storeParameter.value ?? '',
        query: query ?? ''
      });
    });

    $x.on('UserClearedQuery').subscribe(() => {
      setSeo({
        path: router.currentRoute.path,
        store: storeParameter.value ?? ''
      });
    });

    $x.on('ParamsLoadedFromUrl').subscribe(() => {
      const params = new URLSearchParams(location.search);
      const path = location.pathname;

      if (path === '/amagijon') {
        areaParameter.value = 'Asturias';
      } else if (params.get('query')) {
        areaParameter.value = 'madrid';
      } else {
        areaParameter.value = '';
      }

      if (storeParameter.value !== params.get('store')) {
        storeParameter.value = params.get('store');

        if (storeParameter.value) {
          setStoreURLParam(storeParameter.value);
        } else {
          removeStoreURLParam();
        }
      }

      const query = params.get('query');
      if (areaParameter.value && !query && !storeParameter.value) {
        $x.emit('UserOpenedOnBoarding');
      }
    });
  }

  return {
    areaParameter,
    storeParameter,
    areaString,
    goHome,
    setAreaURLParam,
    setStoreURLParam,
    removeStoreURLParam
  };
};
